:host {
  display: block;
}

.sc-donation-choices {
  display: grid;
  gap: var(--sc-spacing-small);
  position: relative;

  &__form {
    display: grid;
    gap: var(--sc-spacing-small);
  }
}
